import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@shared/services/translate.service';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';
import { ILanguage } from '@shared/models/language';
import { InternetConnectionService } from '@shared/services/internet-connection.service';
import { AttendanceParametersService } from '@shared/services/attendance-parameters.service';

const DEFAULT_LANGUAGE = 'nb-no'; //en-gb

@Component({
  selector: 'attendance-top-panel',
  templateUrl: './top-panel.component.html',
  styleUrls: ['./top-panel.component.scss'],
  providers: [InternetConnectionService]
})
export class TopPanelComponent implements OnInit, OnDestroy {
  public languageControl: FormControl;
  public languagesList$: Observable<ILanguage[]>;
  public get connection(): boolean {
    return this.internetConnection.connection;
  }

  constructor(
    private translateService: TranslateService,
    private internetConnection: InternetConnectionService,
    public attendanceParametersService: AttendanceParametersService
  ) {
    this.translateService.setLanguage({abbreviations: DEFAULT_LANGUAGE});
    this.languageControl = new FormControl(DEFAULT_LANGUAGE);
    this._createWatchers();
  }

  private _createWatchers(): void {
    this.languagesList$ = this.translateService.getLanguages();

    this.languageControl.valueChanges.pipe(
      untilDestroyed(this)
    ).subscribe((language) => this.translateService.setLanguage({abbreviations: language}));
  }

  public ngOnInit(): void {}

  public ngOnDestroy(): void {}
}
