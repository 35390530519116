import { Directive } from '@angular/core';

@Directive({
  selector: 'select-error',
  host: {
    'class': 'select-error'
  }
})
export class SelectErrorDirective {
  constructor() { }
}
