import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@shared/services/translate.service';

@Pipe({
  name: 'locale',
  pure: false
})
export class LocalePipe implements PipeTransform {
  constructor(
    private translateService: TranslateService
  ) {}

  transform(value: string): string {
    const activeLanguageKeys = this.translateService.getLanguageByActiveName(this.translateService.activeLanguage);
    if (activeLanguageKeys) return activeLanguageKeys[value] || value;

    return value;
  }
}
