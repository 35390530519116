import {
  AfterViewInit,
  Component,
  ContentChild, ContentChildren,
  ElementRef,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy, QueryList,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  FormControlName,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { SelectTopHintDirective } from '@modules/select/select-top-hint.directive';
import { SelectErrorDirective } from '@modules/select/select-error.directive';

@Component({
  selector: 'attendance-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss', './ng-select.base.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SelectComponent),
    multi: true
  }],
  host: {
    'class': 'attendance-select'
  },
  encapsulation: ViewEncapsulation.None
})
export class SelectComponent implements OnChanges, AfterViewInit, OnDestroy, ControlValueAccessor {
  @ContentChild(FormControlDirective, { static: false }) _parentFormControl: FormControlDirective;
  @ContentChild(FormControlName, { static: false }) _parentFormControlName: FormControlName;

  @ContentChildren(SelectTopHintDirective, { descendants: true }) public selectTopHintChildren: QueryList<SelectTopHintDirective>;
  @ContentChildren(SelectErrorDirective, { descendants: true }) public selectErrorChildren: QueryList<SelectErrorDirective>;

  @Input() public searchable: boolean = true;
  @Input() public bindValue: string = 'id';
  @Input() public bindLabel: string = 'name';
  @Input() public items: any[];
  @Input() public disabled: boolean = false;
  @Input() public clearable: boolean = false;
  @Input() public readonly : boolean = false;
  @Input() public placeholder: string;

  public ngSelectControl: FormControl;
  private selectedOption: any;

  private get parentControl(): FormControlName | FormControlDirective {
    return this._parentFormControlName || this._parentFormControl;
  }

  constructor() {
    this.ngSelectControl = new FormControl({value: '', disabled: this.disabled});
  }

  public changeHandler(value: any): void {
    this.writeValue(value[this.bindValue]);
  }

  public writeValue(value: any): void {
    this.selectedOption = value;
    this.setNgSelectControl(value);
    this.onChange(value);
  }

  public onChange: any = () => {};
  public onTouched: any = () => {};

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private setNgSelectControl(value: any): void {
    if (!this.items) return;
    this.ngSelectControl.setValue(value);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.items && changes.items.currentValue) {
      this.writeValue(this.selectedOption);
    }
  }

  public ngAfterViewInit(): void {}

  public ngOnDestroy(): void {}
}
