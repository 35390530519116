import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import {
  IAttendanceRegister,
  IAttendanceRegisterResponse,
  IPostAttendanceRegister, IVetPostAttendanceRegister
} from '@shared/models/attendance-register';

const initialRegisterResponse = {
  success_status: true,
  data: {}
};

@Injectable({
  providedIn: 'root'
})
export class AttendanceRegisterService {
  constructor(
    private http: HttpClient
  ) { }

  private attendanceRegisterResponse$ = new BehaviorSubject<IAttendanceRegisterResponse>(initialRegisterResponse);

  public get attendanceRegisterResponse(): IAttendanceRegisterResponse {
    return this.attendanceRegisterResponse$.getValue();
  }

  public watchAttendanceRegisterResponse(): Observable<IAttendanceRegisterResponse> {
    return this.attendanceRegisterResponse$.asObservable();
  }

  public setRegisterAttendanceStatus(status: boolean = false): void {
    this.attendanceRegisterResponse$.next({...initialRegisterResponse, success_status: status});
  }

  public postNewAttendance(body: IPostAttendanceRegister): Observable<IAttendanceRegisterResponse<IAttendanceRegister>> {
    return this.http.post<IAttendanceRegister>('attendance/register-attendance-by-dob', body).pipe(
      map((data) => ({
        success_status: true,
        data
      })),
      tap((attendanceResponseData) => this.attendanceRegisterResponse$.next(attendanceResponseData))
    );
  }

  public postNewAttendanceWithSecurity(body: Required<IPostAttendanceRegister>): Observable<IAttendanceRegisterResponse<IAttendanceRegister>> {
    return this.http.post<IAttendanceRegister>(`attendance/register-attendance-by-ssn`, body).pipe(
      map((data) => ({
        success_status: true,
        data
      })),
      tap((attendanceResponseData) => this.attendanceRegisterResponse$.next(attendanceResponseData))
    );
  }

  public postNewAttendanceForVet(body: Required<IVetPostAttendanceRegister>): Observable<IAttendanceRegisterResponse<IAttendanceRegister>> {
    return this.http.post<IAttendanceRegister>(`attendance/register-attendance-by-chip-or-phone`, body).pipe(
      map((data) => ({
        success_status: true,
        data
      })),
      tap((attendanceResponseData) => this.attendanceRegisterResponse$.next(attendanceResponseData))
    );
  }
}
