import { Directive } from '@angular/core';

@Directive({
  selector: 'select-top-hint',
  host: {
    'class': 'select-top-hint'
  }
})
export class SelectTopHintDirective {
  constructor() {}
}
