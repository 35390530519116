import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectComponent } from './select.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SelectTopHintDirective } from './select-top-hint.directive';
import { SelectErrorDirective } from './select-error.directive';
import { ReactiveFormsModule } from '@angular/forms';

const SELECT_COMPONENTS = [
  SelectComponent,
  SelectTopHintDirective,
  SelectErrorDirective
];

@NgModule({
  declarations: [...SELECT_COMPONENTS],
  imports: [
    CommonModule,
    NgSelectModule,
    ReactiveFormsModule
  ],
  exports: [...SELECT_COMPONENTS]
})
export class SelectModule { }
