import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Route, RouterModule } from '@angular/router';
import { CoreComponent } from './core.component';
import { SuccessfulAttendanceGuard } from '@shared/guards/successful-attendance.guard';

const routes: Route[] = [
  {
    path: ':locationSlug',
    component: CoreComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./choose-bellow/choose-bellow.module').then((m) => m.ChooseBellowModule)
      },
      {
        path: 'register-attendance',
        loadChildren: () => import('./register-attendance/register-attendance.module').then((m) => m.RegisterAttendanceModule)
      },
      {
        path: 'successful-attendance',
        canActivate: [SuccessfulAttendanceGuard],
        loadChildren: () => import('./successful-attendance/successful-attendance.module').then((m) => m.SuccessfulAttendanceModule)
      }
    ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class CoreRoutingModule { }
