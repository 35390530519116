import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreComponent } from './core.component';
import { CoreRoutingModule } from './core-routing.module';

import { SharedModule } from '@shared/shared.module';
import { TopPanelComponent } from './components/top-panel/top-panel.component';
import { ReactiveFormsModule } from '@angular/forms';
import { WINDOW_PROVIDERS } from '@shared/providers/window';
import { SelectModule } from '@modules/select/select.module';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [CoreComponent, TopPanelComponent],
  imports: [
    CommonModule,
    CoreRoutingModule,
    SelectModule,
    SharedModule,
    MatIconModule,
    ReactiveFormsModule
  ],
  providers: [
    WINDOW_PROVIDERS
  ]
})
export class CoreModule { }
