import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { AttendanceRegisterService } from '@shared/services/attendance-register.service';
import { pluck } from 'rxjs/operators';
import { IAttendanceRegisterResponse } from '@shared/models/attendance-register';

@Injectable({
  providedIn: 'root'
})
export class SuccessfulAttendanceGuard implements CanActivate {
  constructor(
    private attendanceRegister: AttendanceRegisterService
  ) {}

  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.attendanceRegister.watchAttendanceRegisterResponse().pipe(
      pluck<IAttendanceRegisterResponse, 'success_status'>('success_status')
    );
  }
}
