import {Directive, HostListener} from '@angular/core';

@Directive({
  selector: '[attendancePreventDoubleTapZoom]'
})
export class PreventDoubleTapZoomDirective {

  private lastTouchEnd: number = 0;

  @HostListener('dblclick', ['$event'])
  onDoubleClick(event: Event) {
    event.preventDefault();
  }

  @HostListener('touchstart', ['$event'])
  onTouchStart(event: TouchEvent) {
    const now = new Date().getTime();
    if (now - this.lastTouchEnd <= 300) {
     // event.preventDefault();
    }
    this.lastTouchEnd = now;
  }

}
