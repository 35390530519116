import {Directive, HostListener} from '@angular/core';

@Directive({
  selector: '[attendancePreventDoubleTapZoomSafari]'
})
export class PreventDoubleTapZoomSafariDirective {

  private lastTouchEnd: number = 0;
  private readonly isSafari: boolean;

  constructor() {
    this.isSafari = this.checkIfSafari();
  }

  private checkIfSafari(): boolean {
    const userAgent = navigator.userAgent.toLowerCase();
    return /safari/.test(userAgent) && !/chrome/.test(userAgent); // Safari на macOS та iOS
  }

  @HostListener('dblclick', ['$event'])
  onDoubleClick(event: Event) {
    if (this.isSafari) {
      event.preventDefault();
    }
  }

  @HostListener('touchstart', ['$event'])
  onTouchStart(event: TouchEvent) {
    if (this.isSafari) {
      const now = new Date().getTime();
      if (now - this.lastTouchEnd <= 300) {
        event.preventDefault();
      }
      this.lastTouchEnd = now;
    }
  }
}
