import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { IAttendanceParameters, IAttendanceParametersRaw } from '@shared/models/attendance-parameters';

@Injectable({
  providedIn: 'root'
})
export class AttendanceParametersService {
  constructor(
    private http: HttpClient
  ) { }

  private attendanceParametersSubject = new BehaviorSubject<IAttendanceParameters>({} as IAttendanceParameters);

  public get attendanceParameters(): IAttendanceParameters {
    return this.attendanceParametersSubject.getValue();
  }

  public get attendanceMode(): number {
    // remove ternary when attendance_mode appear
    return this.attendanceParameters && this.attendanceParameters.attendance_mode ? this.attendanceParameters.attendance_mode : 1;
  }

  public watchParameters(): Observable<IAttendanceParameters | any> {
    return this.attendanceParametersSubject.asObservable();
  }

  public getAttendanceParameters(locationSlug: string): Observable<IAttendanceParametersRaw> {
    return this.http.get<IAttendanceParametersRaw>(`attendance/attendance-parameter/${locationSlug}`).pipe(
      tap((parameters) => {
        this.attendanceParametersSubject.next(parameters);
      }),
    )
  }
}
