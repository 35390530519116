import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocalePipe } from './pipes/locale.pipe';
import { PreventDoubleTapZoomDirective } from './directives/prevent-double-tap-zoom.directive';
import {PreventDoubleTapZoomSafariDirective} from '@shared/directives/prevent-double-tap-zoom-safari.directive';

@NgModule({
  declarations: [
    LocalePipe,
    PreventDoubleTapZoomDirective,
    PreventDoubleTapZoomSafariDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    LocalePipe,
    PreventDoubleTapZoomDirective,
    PreventDoubleTapZoomSafariDirective
  ]
})
export class SharedModule { }
