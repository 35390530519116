import { Inject, Injectable, OnDestroy } from '@angular/core';
import { WINDOW_TOKEN } from '@shared/providers/window';

interface IListener {
  actionName: string;
  handler: (...args) => void;
}

@Injectable({
  providedIn: 'root'
})
export class InternetConnectionService implements OnDestroy {
  private _listeners: IListener[] = [];
  private _connection: boolean = true;

  public get connection(): boolean {
    return this._connection;
  }

  constructor(
    @Inject(WINDOW_TOKEN) private window: Window
  ) {
    this.on('online', () => {
      this._connection = true;
    });

    this.on('offline', () => {
      this._connection = false;
    });
  }

  public on(actionName: 'online' | 'offline', handler: (...args) => void): this {
    this.window.addEventListener(actionName, handler);
    this._listeners.push({
      actionName,
      handler
    });

    return this;
  }

  public ngOnDestroy(): void {
    this._listeners.forEach((listener: IListener) => {
      this.window.removeEventListener(listener.actionName, listener.handler);
    });
  }
}
