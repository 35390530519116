import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { switchMap } from 'rxjs/operators';

import { AttendanceParametersService } from '@shared/services/attendance-parameters.service';

@Component({
  selector: 'attendance-core',
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.scss']
})
export class CoreComponent implements OnInit, OnDestroy {

  constructor(
    private activateRoute: ActivatedRoute,
    private attendanceParametersService: AttendanceParametersService
  ) {
    this.watchRouteParams();
  }

  private watchRouteParams(): void {
    this.activateRoute.params.pipe(
      switchMap(({ locationSlug }) => this.attendanceParametersService.getAttendanceParameters(locationSlug)),
      untilDestroyed(this)
    ).subscribe();
  }

  public ngOnInit() {}

  public ngOnDestroy(): void {}
}
