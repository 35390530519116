import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ILanguage } from '@shared/models/language';
import { IParamsRequestKeys } from '@shared/models';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {
  constructor(
    private http: HttpClient
  ) {}

  private _activeLanguage: any;
  private _languages = new Map<string, any>();

  public get activeLanguage() {
    return this._activeLanguage;
  }

  public setLanguage(params: Pick<IParamsRequestKeys, 'abbreviations'>): void {
    if (!this.getLanguageByActiveName(params.abbreviations)) {
      this.http.get(`languages/translations-list`, { params }).subscribe((response) => {
        this._languages.set(params.abbreviations, response);
        this._activeLanguage = params.abbreviations;
      });
      return;
    }

    this._activeLanguage = params.abbreviations;
  }

  public getLanguages(): Observable<ILanguage[]> {
    return this.http.get<ILanguage[]>('languages/list');
  }

  public getLanguageByActiveName(languageName: string): any {
    return this._languages.get(languageName);
  }
}
